<template>
    <div class="h-full">
        <fixed-list :dataSource="dataSource" :config="config" @onDataSourceChange="onDataSourceChange" @onPageChange="onPageChange" :queryParam="queryParam">
            <div slot="buttonGroup" class="h-handle-button" >
                <div class="h-b"><el-button type="primary" size="small" @click="addNotice" v-right-code="'Manage_Right_Message_Notice_AddEditNotice'">新增</el-button> </div>
                <div class="h-b"><el-button type="primary" size="small" @click="delNotice" v-right-code="'Manage_Right_Message_Notice_AddEditNotice'">删除</el-button> </div>
            </div>
            <p slot="elList">
                <el-table ref="noticeListTable" :data="dataSource.Result" border @selection-change="handleSelectionChange" highlight-current-row>
                    <el-table-column type="selection" width="55" v-if="!config.isDetailDisplay"></el-table-column>
                    <el-table-column v-for="(col,index,count)  in dataSource.ColDefs.BodyFieldParams"
                                     :key="index"
                                     :prop="col.FieldName"
                                     :label="col.DisplayName"
                                     :render-header="bindFilter(queryParam,col)"
                                     :fixed="index==0&&(!config||!config.isDetailDisplay)"
                                     :min-width="(!config||!config.isDetailDisplay)?parseInt(col.Width):0"
                                     v-if="col.Visible&&(!config||!config.isDetailDisplay||(config.isDetailDisplay&&index<2))">
                        <template slot-scope="scope">
                            <span v-if="col.FieldName==='NoticeTItle'"><span type="text" @click="rowClick(scope.row)" style="text-decoration:underline;color:#1874CD;cursor:pointer">{{ scope.row[col.FieldName] }}</span></span>
                            <span v-else>  {{ scope.row[col.FieldName] }}</span>
                        </template>
                    </el-table-column>
                   
                </el-table>
            </p>
        </fixed-list>
        
       
    </div>
</template>
<script>
    export default {
        mounted() {
            this.Event.$on("reloadNoticePageList", () => this.reloadPageList());
            this.Event.$on("onAddNotice", () => this.addNotice());
            this.initialize();
        },
        data() {
            return {
               
                multipleSelection: [],
               
                queryParam: {
                    PageIndex: 1,
                    PageSize: 10
                },
                dataSource: {
                    ColDefs: {},
                    Result: [],
                    TotalCount: 0
                },
                
            }
        },

        props: {
            config: {
                isDetailDisplay: false,
                isButtonlDisplay: false,
            },
            option: {}
        },
        methods: {
            
            reloadPageList: function () {
                this.initialize();
            },
            initialize() {
                this.onPageChange(this.queryParam);
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
                this.$emit("onCheckRow", this.multipleSelection);
            },
            onPageChange(param) {
                var _this = this;
                this.queryParam = param;
                this.$ajax.query("omsapi/msgnotice/getmsgnotices", "post", param, data => {

                    _this.dataSource = data;

                  
                });
            },
            onDataSourceChange(ds) {
                var _this = this;

                _this.dataSource = {
                    ColDefs: {
                        BodyFieldParams: []
                    },
                    Result: [],
                    TotalCount: 0
                };
                _this.$nextTick(function () {
                    _this.dataSource = ds;

                });


            },
            rowClick(row) {
                this.$ajax.send("omsapi/msgnotice/search", "get", { id: row.NoticeID }, (data) => {
                    this.Event.$emit("clearEditNoticeForm");
                    this.onChangeEditDataSource(data.Result);
                });
            },
            addNotice() {
                this.$ajax.send("omsapi/msgnotice/getemptynotice", "get", {}, (data) => {
                    this.onChangeEditDataSource(data.Result);
                });
            },
            delNotice() {
                if (this.multipleSelection == null || this.multipleSelection.length == 0) {
                    this.Utils.messageBox(`未选择删除的公告.`, "error");
                    return false;
                }
                this.$ajax.send("omsapi/msgnotice/remove", "post", this.multipleSelection, (data) => {
                    this.initialize();
                    this.Utils.messageBox("删除成功.", "info");
                });
            },
            onChangeEditDataSource(data) {
                this.config.isDetailDisplay = true;
                this.$emit("onChangeEditDataSource", data);
            }
            
        }

    }
</script>
<style>

</style>